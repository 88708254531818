/* Base styles for the App */
.App {
  font-family: apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  text-align: center;
}

/* Header styles */
.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 5vmin);
  color: whitesmoke;
  background: #121212;
  padding: 20px;
}

.Header-text,
.error {
  font-size: 1.4rem;
  font-weight: 600;
  color: #ffffff;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
  letter-spacing: 1px;
  margin-bottom: 50px;
  opacity: .87;
}

.progress-bar {
  width: 50%;
  height: 20px;
  margin: 0 50px;
}

.error {
  color: lightcoral;
}

.Description {
  font-size: 1.2rem;
  font-weight: 500;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
  margin-top: 200px;
  opacity: .6;
  visibility: visible;
}

.Description.hidden {
  visibility: hidden;
}

/* Button styles for dark theme */
.button {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  border: 1px solid rgba(255, 255, 255, 0.2);
  /* Lighter border for visibility */
  border-radius: 6px;
  background-color: #24292E;
  /* Dark background */
  color: #FFFFFF;
  /* White text color */
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  padding: 6px 15px;
  transition: all 0.3s ease-in-out;
  /* Smooth transition for hover effects */
  margin-top: 20px;
}

.button:hover {
  background-color: #444950;
  /* Slightly lighter background on hover */
  border-color: #FFFFFF;
  /* White border on hover */
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  /* Adds space below the button container */
}

/* Logo styles including animation */
.App-logo {
  margin-bottom: 20px;
  width: 100px;
  cursor: pointer;
  border: 3px solid white;
  padding: 30px;
  border-radius: 50%;
  background: linear-gradient(#0d1130, #44f);
  margin-bottom: 100px;
  margin-top: 50px;
}

.App-logo:hover {
  border: 3px solid lightblue;
}

.input-container {
  display: flex;
  width: 100%;
  /* Padding to keep some space from the edges */
  margin: 20px;
}

.youtube-input {
  flex: 1;
  /* Allow the input to expand and take up available space */
  /* Space between the input and the button */
  padding: 8px 12px;
  font-size: 16px;
  /* Readable font size for mobile */
}

.youtube-button {
  background: #5c57ff;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  color: white;
  cursor: pointer;
  font-family: apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  margin-left: 3px;
  font-weight: 500;
  padding: 6px 16px;
  transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
}

.audio-player {
  /* Limiting the maximum size */
  margin-top: 20px;
  margin-bottom: 20px;
  /* Dark background to match theme */
}

.or-text {
  margin: 0 20px;
  /* Adequate spacing around the OR text */
  font-size: 1.4rem;
  /* Matching font size with header text */
  color: #ffffff;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
  opacity: 0.87;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animated {
  animation: fadeInUp 1s ease forwards;
}

@keyframes typewriter {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.typewriter {
  overflow: hidden;
  /* Ensures the content is not revealed until the animation */
  white-space: nowrap;
  /* Keeps the content on a single line */
  letter-spacing: .15em;
  /* Adjusts the spacing between characters */
  animation: typewriter 3.5s steps(40, end) 1s 1 normal both;
}

@keyframes blinkTextCursor {
  from {
    border-right-color: orange;
  }

  to {
    border-right-color: transparent;
  }
}

@keyframes pulseRotateAnimation {

  0%,
  100% {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }

  25% {
    transform: scale(1.05) rotate(90deg);
    /* Increased scale */
    opacity: 0.7;
    /* More noticeable opacity change */
  }

  50% {
    transform: scale(1) rotate(180deg);
    opacity: 1;
  }

  75% {
    transform: scale(1.05) rotate(270deg);
    /* Increased scale */
    opacity: 0.7;
    /* More noticeable opacity change */
  }
}

.pulseRotate {
  animation: pulseRotateAnimation 10s infinite ease-in-out;
  /* Shorter duration for more noticeable effects */
}

/* Media queries for responsive design */
@media (min-width: 481px) {
  .App-logo {
    width: 110px;
    height: 110px;
  }

  .input-container {
    width: 90%;
    /* Slightly narrower on larger screens for aesthetics */
    padding: 0 20px;
    /* More padding as screen gets wider */
  }

  .youtube-input {
    margin-right: 15px;
    /* More space between the input and button on larger screens */
  }

  .youtube-button {
    width: 120px;
    /* Larger button width for larger screens */
  }
}

@media (min-width: 769px) {
  .App-header {
    font-size: calc(10px + 1vmin);
  }

  .input-container {
    width: 80%;
    /* Further narrowing on very large screens */
    max-width: 600px;
    /* Maximum width cap to avoid overly wide elements */
  }
}

@media (min-width: 1024px) {
  .input-container {
    width: 70%;
    /* More aesthetic spacing for very large screens */
  }
}

.modal {
  display: block; 
  position: fixed; 
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgba(0,0,0,0.4); 
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto; 
  padding: 20px;
  border: 1px solid #888;
  width: 80%; 
  max-width: 400px;
  border-radius: 10px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

button {
  display: inline-block;
  padding: 10px 20px;
  margin: 10px 0;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}


