body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #333;
  color: #fff;
}

.navbar-logo a {
  color: white;
  font-weight: lighter;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 1.5rem;
  margin-left: 5px;
}

.navbar-logo a:hover {
  color:  #a66dc2;
}

.navbar-links {
  list-style: none;
  display: flex;
}

.navbar-links li {
  margin-left: 1rem;
}

.navbar-links a {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: background 0.3s;
}

.navbar-links a:hover {
  background-color: #575757;
}

/* Mobile styles */
@media (max-width: 768px) {
  .navbar-links {
    display: flex;
    flex-direction: column; /* Stack links vertically */
    align-items: center;
    background-color: #121212; /* Match the header background */
    position: absolute; 
    top: 100%; /* Position below the navbar */
    left: 0;
    width: 100%; /* Full width on small screens */
    padding: 20px 0; /* Adequate padding */
    z-index: 1;
  }

  .navbar-links li {
    margin: 10px 0; /* Space out the links */
  }

  .navbar-logo {
    margin-left: 10px; /* Add some space around the logo */
  }

  /* Hide login/signup links in mobile and use modals instead */
  .navbar-links li:last-child,
  .navbar-links li:nth-last-child(2) {
    display: none;
  }
}



.section {
  padding: 100px 20px;
  text-align: center;
  color: white;
}

/* Specific section gradients */
#home {
  background: rgb(68, 68, 255);
}

#about {
  background: #333;
}

#features {
  background: black;
}

#pricing {
  background: #121212;
}

#contact {
  background: rgb(68, 68, 255);
}

/* Text styles */
.section h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.section p, .section ul {
  font-size: 1.2em;
  line-height: 1.5;
}

.section ul {
  list-style-type: none;
  padding: 0;
}

.section ul li {
  margin: 10px 0;
}

html {
  scroll-behavior: smooth;
}
